import classnames from 'classnames';

import Conversion from 'bloko/blocks/conversion';
import { ClockScaleSmallKindDefault, IconColor } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';

const ONE_MINUTE = 60;

interface ApplicantSkillsVerificationPeriodProps {
    seconds: number;
    className: string;
    grey?: boolean;
    black?: boolean;
}

const TrlKeys = {
    one: 'applicantSkillsVerificationMethods.one',
    some: 'applicantSkillsVerificationMethods.some',
    many: 'applicantSkillsVerificationMethods.many',
};

const ApplicantSkillsVerificationPeriod: TranslatedComponent<ApplicantSkillsVerificationPeriodProps> = ({
    trls,
    seconds,
    className,
    grey,
    black,
}) => {
    const minutes = seconds / ONE_MINUTE;
    if (!minutes) {
        return null;
    }

    return (
        <div className={className} data-qa={'verification-method-time'}>
            <ClockScaleSmallKindDefault initial={black ? IconColor.Gray80 : IconColor.Gray50} />
            <span
                className={classnames('resume-key-skills-verification-method-period', {
                    'resume-key-skills-verification-method-period-black': black,
                    'resume-key-skills-verification-method-period-grey': grey,
                })}
            >
                <Conversion
                    one={trls[TrlKeys.one]}
                    some={trls[TrlKeys.some]}
                    many={trls[TrlKeys.many]}
                    format={(trl) => format(trl, { '{0}': minutes })}
                    value={minutes}
                />
            </span>
        </div>
    );
};

export default translation(ApplicantSkillsVerificationPeriod);
