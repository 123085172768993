import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import isValidKey from 'bloko/common/isValidKey';
import { format, formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { ResumeRecommendationVariants } from 'src/utils/constants/resumeRecommendations';

import useApplicantServicesRecommendationLink, {
    RECOMMENDATIONS_LINKS_MAP,
} from 'src/pages/ApplicantResumes/components/useApplicantServicesRecommendationLink';

interface ResumeRecommendationsTextProps {
    recommendation: ResumeRecommendationVariants;
    recommendationDetails: {
        translations?: {
            resumeRecommendationTextProfession?: string;
        };
    };
}

const TrlKeys = {
    respond: 'resumeList.recommendations.text.response',
    editResume: 'resumeList.recommendations.text.edit',
    [`editResume.withLink`]: 'resumeList.recommendations.text.edit.applicantRecommendations',
    [`editResume.withLink.Link`]: 'resumeList.recommendations.text.edit.applicantRecommendations.link',
    updateResume: 'resumeList.recommendations.text.update',
    [`updateResume.withLink`]: 'resumeList.recommendations.text.update.applicantRecommendations',
    [`updateResume.withLink.Link`]: 'resumeList.recommendations.text.update.applicantRecommendations.link',
    changeVisibility: 'resumeList.recommendations.text.visibility',
    [`changeVisibility.withLink`]: 'resumeList.recommendations.text.visibility.applicantRecommendations',
    [`changeVisibility.withLink.Link`]: 'resumeList.recommendations.text.visibility.applicantRecommendations.link',
    correctResume: 'resumeList.recommendations.text.blocked',
    onModeration: 'resumeList.recommendations.text.onModeration',
    needVerifiedPhone: 'resumeList.recommendations.text.verification',
    needSkillsSurveyBase: 'resumeList.recommendations.text.skillsSurvey.base',
};

const ResumeRecommendationText: TranslatedComponent<ResumeRecommendationsTextProps> = ({
    trls,
    recommendation,
    recommendationDetails,
}) => {
    const recommendationLink = useApplicantServicesRecommendationLink(recommendation);

    if (isValidKey(RECOMMENDATIONS_LINKS_MAP, recommendation) && recommendationLink) {
        return (
            <>
                {formatToReactComponent(trls[TrlKeys[`${recommendation}.withLink`]], {
                    '{0}': (
                        <BlokoLink Element={Link} to={recommendationLink}>
                            {trls[TrlKeys[`${recommendation}.withLink.Link`]]}
                        </BlokoLink>
                    ),
                })}
            </>
        );
    }

    if (recommendation === ResumeRecommendationVariants.NeedSkillSurvey) {
        return (
            <>
                {format(trls[TrlKeys.needSkillsSurveyBase], {
                    '{0}': recommendationDetails.translations?.resumeRecommendationTextProfession || '',
                })}
            </>
        );
    }

    return <>{trls[TrlKeys[recommendation]]}</>;
};

export default translation(ResumeRecommendationText);
