import { ComponentType, PropsWithChildren } from 'react';

import phoneVerificationElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/phone_verification/phone_verification_element_shown';
import { GridColumn, GridRow, Title, useBreakpoint, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';

import MagritteSideBarLayout from 'src/app/layouts/BlokoLayouts/MagritteSideBarLayout';
import PageLayout from 'src/app/layouts/PageLayout';
import PhoneVerification from 'src/components/ApplicantResumes/PhoneVerification';
import ApplicantSideBar from 'src/components/ApplicantSideBar';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import TestFinishModal from 'src/components/TestCardRank/TestFinishModal';
import translation from 'src/components/translation';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsApplicantProfileBlocked } from 'src/models/applicantProfile/moderation';
import { ApplicantSkillsVerificationResultKeys } from 'src/models/applicantSkillsVerificationResult';
import ApplicantSkillsVerificationError from 'src/pages/ApplicantResumes/components/ApplicantSkillsVerificationError';
import DisabledContainer from 'src/pages/ApplicantResumes/components/DisabledContainer';
import useApplicantResumesNotifications from 'src/pages/ApplicantResumes/components/useApplicantResumesNotifications';

import ApplicantNavigation from 'src/pages/ApplicantResumes/components/redesign/ApplicantNavigation';
import ApplicantResumes from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes';
import ApplicantServicesSlider from 'src/pages/ApplicantResumes/components/redesign/ApplicantServicesSlider';
import JobSearchStatus from 'src/pages/ApplicantResumes/components/redesign/JobSearchStatus';
import ResumeMergeActivator from 'src/pages/ApplicantResumes/components/redesign/ResumeMergeActivator';
import VacancyResponseCounter from 'src/pages/ApplicantResumes/components/redesign/VacancyResponseCounter';

import styles from './styles.less';

const TrlKeys = {
    title: 'resumeList.title',
};

const FullRowBlock: ComponentType<PropsWithChildren> = ({ children }) => {
    return (
        <GridRow>
            <GridColumn xs={4} s={8} m={8} l={8} xl={8} xxl={8}>
                {children}
            </GridColumn>
        </GridRow>
    );
};

const ApplicantResumesPage = translation(({ trls }) => {
    const { isGtM, isMobile } = useBreakpoint();
    const isProfileBlocked = useIsApplicantProfileBlocked();
    const applicantResumesStatistics = useSelector(({ applicantResumesStatistics }) => applicantResumesStatistics);
    const recommendations = applicantResumesStatistics.recommendationsForAllResumes;
    const resumes = useSelector(({ applicantResumes }) => applicantResumes);
    const enableResumesMerge = useSelector(({ enableResumesMerge }) => enableResumesMerge);

    const hasCodePracticeBanner = useSelector((state) => !!state.microFrontends.codePracticeBanner);
    const hasSkillsVerificationCard = useSelector((state) => !!state.microFrontends.skillsVerificationCard);

    const skillsVerificationResults = useSelector(
        ({ applicantSkillsVerificationResult }) => applicantSkillsVerificationResult
    );
    const applicantSkillsVerificationError = useSelector(
        ({ applicantSkillsVerificationError }) => applicantSkillsVerificationError
    );
    const latestResume = resumes.sort((a, b) => a.updatedHuman - b.updatedHuman)[0];
    const needVerifiedPhone = recommendations?.needVerifiedPhone;
    const needResponseStreak = recommendations?.needResponseStreak;
    const isHeadHunterPlatform = useIsHeadHunterPlatform();

    useApplicantResumesNotifications();
    return (
        <PageLayout title={trls[TrlKeys.title]} layout={MagritteSideBarLayout} sidebar={ApplicantSideBar}>
            <VSpacingContainer default={16} gteS={24}>
                {!isMobile && (
                    <GridRow>
                        <GridColumn xs={0} s={0} m={5} l={5} xl={5} xxl={5}>
                            <Title size={'large'} Element="h4">
                                {trls[TrlKeys.title]}
                            </Title>
                        </GridColumn>
                        <GridColumn xs={0} s={0} m={3} l={3} xl={3} xxl={3}>
                            <ApplicantNavigation />
                        </GridColumn>
                    </GridRow>
                )}

                {isProfileBlocked && <ContainerForMicroFrontend place={'profileBlockedBanner'} />}

                <FullRowBlock>
                    {isGtM ? (
                        <div className={styles.profileTwoColumnWrapper}>
                            {/* чтобы до момента закрузки микрофронта страница не дергалась, */}
                            {/* оборачиваем в div, который будет иметь фиксированный размер*/}
                            <div>
                                <ContainerForMicroFrontend place={'profileActivator'} />
                            </div>
                            <DisabledContainer disabled={isProfileBlocked}>
                                <JobSearchStatus />
                            </DisabledContainer>
                        </div>
                    ) : (
                        <VSpacingContainer default={16} gteS={24}>
                            <ContainerForMicroFrontend place={'profileActivator'} />
                            <DisabledContainer disabled={isProfileBlocked}>
                                <JobSearchStatus />
                            </DisabledContainer>
                        </VSpacingContainer>
                    )}
                </FullRowBlock>

                {needVerifiedPhone && !isProfileBlocked && (
                    <FullRowBlock>
                        <ElementShownAnchor fn={phoneVerificationElementShown}>
                            <PhoneVerification
                                resumes={resumes}
                                isOnResumeListPage
                                initialPhone={recommendations.phone}
                            />
                        </ElementShownAnchor>
                    </FullRowBlock>
                )}
                {latestResume && needResponseStreak && (
                    <FullRowBlock>
                        <VacancyResponseCounter relatedVacanciesHash={latestResume._attributes.hash} />
                    </FullRowBlock>
                )}

                <FullRowBlock>
                    <ApplicantServicesSlider />
                </FullRowBlock>

                {enableResumesMerge && (
                    <FullRowBlock>
                        <ResumeMergeActivator />
                    </FullRowBlock>
                )}
                <FullRowBlock>
                    <ApplicantResumes />
                </FullRowBlock>
                {hasCodePracticeBanner && (
                    <FullRowBlock>
                        <ContainerForMicroFrontend place={'codePracticeBanner'} />
                    </FullRowBlock>
                )}

                {isHeadHunterPlatform && hasSkillsVerificationCard && (
                    <FullRowBlock>
                        <ContainerForMicroFrontend place={'skillsVerificationCard'} />
                    </FullRowBlock>
                )}

                <GridRow>
                    <GridColumn xs={4} s={8} m={0} l={0} xl={0} xxl={0}>
                        <ApplicantNavigation />
                    </GridColumn>
                </GridRow>
                <VSpacing default={16} gteS={0} />
                {skillsVerificationResults && (
                    <TestFinishModal
                        results={skillsVerificationResults}
                        name={skillsVerificationResults?.items?.[0]?.name}
                        rank={skillsVerificationResults?.items?.[0]?.level?.rank}
                        success={skillsVerificationResults?.items?.[0]?.result.status === 'SUCCESS'}
                        education={Boolean(
                            skillsVerificationResults?.items?.[0]?.[ApplicantSkillsVerificationResultKeys.CourseUrl] &&
                                skillsVerificationResults.method[
                                    ApplicantSkillsVerificationResultKeys.BrandingSettings
                                ][ApplicantSkillsVerificationResultKeys.CtaButtonEnabled]
                        )}
                        educationUrl={
                            skillsVerificationResults?.items?.[0]?.[ApplicantSkillsVerificationResultKeys.CourseUrl]
                        }
                        correctAnswers={skillsVerificationResults?.items?.[0]?.result?.score?.actual}
                        questionsAmount={skillsVerificationResults?.items?.[0]?.result?.score?.max}
                        isSkill={skillsVerificationResults?.items?.[0]?.category === 'SKILL'}
                        sourceName={skillsVerificationResults?.method?.source?.name}
                    />
                )}
                {applicantSkillsVerificationError && <ApplicantSkillsVerificationError />}
            </VSpacingContainer>
        </PageLayout>
    );
});

export default ApplicantResumesPage;
