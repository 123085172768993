/* eslint-disable import/prefer-default-export */
import { Status } from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_button_click';

import { Statuses } from 'src/models/resume/resumeCommon.types';

export const getAnalyticsStatus = (statuses: Statuses): Status => {
    if (statuses === Statuses.Blocked) {
        return 'blocked';
    }
    if (statuses === Statuses.NotFinished) {
        return 'not_published';
    }
    if (statuses === Statuses.Corrected) {
        return 'on_moderation';
    }
    return 'published';
};
