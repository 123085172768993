import isValidKey from 'bloko/common/isValidKey';

import { ApplicantServices } from 'src/components/ApplicantServices/common';
import { useSelector } from 'src/hooks/useSelector';
import { ResumeRecommendationVariants } from 'src/utils/constants/resumeRecommendations';

export const RECOMMENDATIONS_LINKS_MAP = {
    [ResumeRecommendationVariants.EditResume]: '/expert_resume?from=resume_recommendation',
    [ResumeRecommendationVariants.UpdateResume]: '/applicant/services/findjob?from=resume_recommendation',
    [ResumeRecommendationVariants.ChangeVisibility]: '/article/98?from=resume_recommendation',
} as const;

const RECOMMENDATION_TO_SERVICE_MAP = {
    [ResumeRecommendationVariants.EditResume]: ApplicantServices.CompleteResume,
    [ResumeRecommendationVariants.UpdateResume]: ApplicantServices.ResumeRenewal,
    [ResumeRecommendationVariants.ChangeVisibility]: ApplicantServices.ResumeTargetEmployer,
} as const;

export default (recommendation: ResumeRecommendationVariants): null | string => {
    const applicantPaymentServices = useSelector((state) => state.applicantPaymentServices);

    if (!isValidKey(RECOMMENDATIONS_LINKS_MAP, recommendation)) {
        return null;
    }

    if (applicantPaymentServices.includes(RECOMMENDATION_TO_SERVICE_MAP[recommendation])) {
        return RECOMMENDATIONS_LINKS_MAP[recommendation];
    }

    return null;
};
