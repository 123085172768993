/* eslint-disable import/prefer-default-export */
import { ReactElement } from 'react';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import { Button, ButtonStyle, ButtonSize } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    visibilityChange: 'resumeList.actionline.visibility.change',
};

type Props = {
    hash: string;
    icon?: ReactElement;
    size?: ButtonSize;
    style?: ButtonStyle;
    resumeId: number;
    stretched?: boolean;
};

export const VisibilityAction = translation<Props>(
    ({ hash, trls, style = 'accent', size = 'small', resumeId, stretched = false, icon }) => {
        const url = useSelector(({ request }) => request.url);
        const additionalProps: { icon?: never } | { icon: ReactElement } = { ...(icon && { icon }) };

        return (
            <Button
                style={style}
                Element={Link}
                size={size}
                mode="tertiary"
                to={`/applicant/resumes/edit/visibility?backUrl=${url}&resume=${hash}`}
                onClick={() => {
                    resumeActionLinkButtonClick({ buttonName: 'resume_change_visibility', resumeId });
                }}
                stretched={stretched}
                {...additionalProps}
            >
                {trls[TrlKeys.visibilityChange]}
            </Button>
        );
    }
);
