import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';

import translation from 'src/components/translation';

const ResumeStatisticsItem = ({ type, link, count, countNew, trls, onClick }) => {
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    if (count === undefined) {
        return null;
    }

    const dataQA = {
        views: 'count-new-views',
        invitations: 'new-invitations',
        searchShows: 'search-shows',
    };

    const conversion = (
        <Conversion
            value={countNew || count}
            hasValue={false}
            one={trls[ResumeStatisticsItem.trls[`${type}.one`]]}
            some={trls[ResumeStatisticsItem.trls[`${type}.some`]]}
            many={trls[ResumeStatisticsItem.trls[`${type}.many`]]}
            zero={trls[ResumeStatisticsItem.trls[`${type}.many`]]}
        />
    );

    return (
        <div className="applicant-resumes-statistics-list-item" data-qa={dataQA[type]}>
            <Text Element="span" strong size={TextSize.Large}>
                {count} {!!countNew && <span className="applicant-resumes-statistics-positive">+{countNew} </span>}
            </Text>
            <span className="applicant-resumes-statistics-list-item-title">
                {link ? (
                    <BlokoLink Element={Link} disableVisited to={link} onClick={handleClick}>
                        {conversion}
                    </BlokoLink>
                ) : (
                    <>{conversion}</>
                )}
            </span>
        </div>
    );
};

ResumeStatisticsItem.propTypes = {
    trls: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    link: PropTypes.string,
    count: PropTypes.number,
    countNew: PropTypes.number,
    onClick: PropTypes.func,
};

ResumeStatisticsItem.trls = {
    emptyNum: 'resumeList.statistics.emptyNum',
    'searchShows.one': 'resumeList.statistics.searchShows.one',
    'searchShows.some': 'resumeList.statistics.searchShows.some',
    'searchShows.many': 'resumeList.statistics.searchShows.many',
    'views.one': 'resumeList.statistics.views.one',
    'views.some': 'resumeList.statistics.views.some',
    'views.many': 'resumeList.statistics.views.many',
    'invitations.one': 'resumeList.statistics.invitations.one',
    'invitations.some': 'resumeList.statistics.invitations.some',
    'invitations.many': 'resumeList.statistics.invitations.many',
    week: 'resumeList.statistics.week',
    comparison: 'resumeList.statistics.comparison',
    comparisonPositive: 'resumeList.statistics.comparison.positive',
    comparisonNegative: 'resumeList.statistics.comparison.negative',
    comparisonEqual: 'resumeList.statistics.comparisonEqual',
};

const ResumeStatisticsItemTrl = translation(ResumeStatisticsItem);

export default ResumeStatisticsItemTrl;

export { ResumeStatisticsItemTrl as ResumeStatisticsItemPure };
