/* eslint-disable import/prefer-default-export */
import { useState, useRef, useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import resumeRecommendationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_button_click';
import resumeRecommendationElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_element_shown';
import { Button, TooltipHover } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useBotUpdateResumeContext } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/botUpdateResume';

const TrlKeys = {
    error: 'rb.touch.error',
    update: 'resumeList.recommendations.action.update',
    updateSuccess: 'resumeList.recommendations.action.update.success',
    updateByBotButton: 'botUpdateResume.updateByBotButton',
};

type Props = {
    error?: boolean;
    message?: string;
    disabled?: boolean;
    doUpdate?: () => void;
};

export const UpdateButton = translation<Props>(({ disabled = false, doUpdate, error = false, message = '', trls }) => {
    const activatorRef = useRef<HTMLButtonElement | null>(null);

    const [clicked, setClicked] = useState(false);

    const { successUpdate, resumeId, recommendationDetails, canUpdateResumeByBot, chatBotHref } =
        useBotUpdateResumeContext();

    let displayMessage = null;
    if (error) {
        displayMessage = trls[TrlKeys.error];
    } else if (disabled) {
        displayMessage = message;
    }

    const caption = clicked && disabled ? trls[TrlKeys.updateSuccess] : trls[TrlKeys.update];

    const update = () => {
        setClicked(true);
        doUpdate?.();
        resumeRecommendationButtonClick({
            type: 'updateResume',
            resumeId,
            surveyProfession: recommendationDetails?.surveyProfession || '',
        });
    };

    const handleByBotClick = useCallback(() => {
        Analytics.sendEvent('applicant', 'resume_renew_bot', 'click');
        resumeRecommendationButtonClick({
            type: 'updateResumeByBot',
            resumeId,
            surveyProfession: recommendationDetails?.surveyProfession || '',
        });
    }, [resumeId, recommendationDetails]);

    if (canUpdateResumeByBot && successUpdate) {
        return (
            <ElementShownAnchor
                fn={resumeRecommendationElementShown}
                type="updateResumeByBot"
                resumeId={resumeId}
                surveyProfession={recommendationDetails?.surveyProfession || ''}
            >
                <Button
                    Element={Link}
                    size="small"
                    mode="secondary"
                    style="accent"
                    to={chatBotHref}
                    onClick={handleByBotClick}
                    data-qa="resume-recommendations__button_update-by-bot"
                >
                    {trls[TrlKeys.updateByBotButton]}
                </Button>
            </ElementShownAnchor>
        );
    }

    return (
        <>
            <Button
                ref={activatorRef}
                size="small"
                mode="primary"
                style="accent"
                onClick={update}
                data-qa="resume-update-button"
                disabled={disabled}
            >
                {caption}
            </Button>
            {displayMessage && (
                <TooltipHover
                    data-qa="resume-update-button-tooltip"
                    placement="right-bottom"
                    activatorRef={activatorRef}
                >
                    {displayMessage}
                </TooltipHover>
            )}
        </>
    );
});
