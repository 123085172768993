import { FC } from 'react';

import resumeElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_element_shown';
import { VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { useSelector } from 'src/hooks/useSelector';

import { FindJobPromo } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/FindJobPromo';
import { ResumeCard } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeCard';
import { getAnalyticsStatus } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeCard/analytics';
import { useFindJobPromoEnabled } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/hooks/useFindJobPromoEnabled';

const ApplicantResumes: FC = () => {
    const latestResumeHash = useSelector((state) => state.latestResumeHash);
    const publishedResumes = useSelector(({ applicantResumes }) =>
        applicantResumes.filter(({ _attributes }) => _attributes.isSearchable)
    );
    const unpublishedResumes = useSelector(({ applicantResumes }) =>
        applicantResumes.filter(({ _attributes }) => !_attributes.isSearchable)
    );

    const hasCodePracticeBanner = useSelector((state) => !!state.microFrontends.codePracticeBanner);
    const hasCareerPlatformBanner = useSelector(
        (state) => !!Object.keys(state.microFrontends).find((key) => key.startsWith('careerPlatformBannerByResumeHash'))
    );

    const showFindJobPromo = useFindJobPromoEnabled();

    return (
        <VSpacingContainer default={16} gteS={24}>
            {publishedResumes.concat(unpublishedResumes).map((resume, index, resumeList) => {
                const { _attributes } = resume;

                return (
                    <ElementShownAnchor
                        key={resume._attributes.id}
                        fn={resumeElementShown}
                        status={getAnalyticsStatus(_attributes.status)}
                        position={index}
                        resumeId={_attributes.id}
                    >
                        <ResumeCard resume={resume} position={index} />
                        {showFindJobPromo && index === 0 && (
                            <>
                                <VSpacing default={16} gteS={24} />
                                <FindJobPromo />
                            </>
                        )}
                        {latestResumeHash === resume._attributes.hash &&
                            hasCareerPlatformBanner &&
                            !(hasCodePracticeBanner && resumeList.length - 1 === index) && (
                                <>
                                    <VSpacing default={16} gteS={24} />
                                    <ContainerForMicroFrontend
                                        place={`careerPlatformBannerByResumeHash-${latestResumeHash}`}
                                    />
                                </>
                            )}
                    </ElementShownAnchor>
                );
            })}
        </VSpacingContainer>
    );
};

export default ApplicantResumes;
