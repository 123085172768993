import { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';

import translation from 'src/components/translation';

const ResumeVisibility = ({ hash, url, trls, id }) => {
    const handleClick = useCallback(() => {
        resumeActionLinkButtonClick({ buttonName: 'resume_change_visibility', resumeId: id });
    }, [id]);

    return (
        <BlokoLink
            Element={Link}
            to={`/applicant/resumes/edit/visibility?backUrl=${url}&resume=${hash}`}
            disableVisited
            onClick={handleClick}
        >
            {trls[ResumeVisibility.trls.visibilityChange]}
        </BlokoLink>
    );
};

ResumeVisibility.trls = {
    visibilityChange: 'resumeList.actionline.visibility.change',
};

ResumeVisibility.propTypes = {
    url: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    trls: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};

export default connect((state) => ({
    url: state.request.url,
}))(translation(ResumeVisibility));
