/* eslint-disable import/prefer-default-export */
import { Card, Title, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

const TrlKeys = {
    isPreparingByExpert: 'resumeList.resume.status.isPreparingByExpert',
};

export const PreparingByExpertCard = translation<{ id: string; title: string }>(({ id, title, trls }) => {
    const { isMobile } = useBreakpoint();

    return (
        <Card
            stretched
            showBorder
            padding={24}
            borderRadius={24}
            data-qa="resume"
            data-qa-id={id}
            data-qa-title={title}
        >
            <Title
                data-qa="resume-title"
                Element="h3"
                size={isMobile ? 'extra-small' : 'medium'}
                description={trls[TrlKeys.isPreparingByExpert]}
            >
                {title}
            </Title>
        </Card>
    );
});
