/* eslint-disable import/prefer-default-export */
import bannerElementShown from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_element_shown';
import bannerPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_primary_button_click';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { Banner } from 'src/pages/ApplicantResumes/components/redesign/Banner';

const PROMO_LINK = '/applicant/services/payment?from=myresumes_promo&hhtmFromLabel=myresumes_promo';

const TrlKeys = {
    title: 'FindJobPromo.title',
    order: 'FindJobPromo.activate',
    description: 'FindJobPromo.description',
};

export const FindJobPromo = translation(({ trls }) => {
    return (
        <ElementShownAnchor fn={bannerElementShown} bannerName="resume_renewal_banner" hhtmSource="resume_list">
            <Banner
                href={PROMO_LINK}
                title={trls[TrlKeys.title]}
                button={trls[TrlKeys.order]}
                description={trls[TrlKeys.description]}
                onClick={() =>
                    bannerPrimaryButtonClick({
                        bannerName: 'resume_renewal_banner',
                        hhtmSource: 'resume_list',
                        buttonText: trls[TrlKeys.order],
                    })
                }
            />
        </ElementShownAnchor>
    );
});
