import { FC } from 'react';
import classnames from 'classnames';

import ApplicantSkillsVerificationIcon from 'src/components/ApplicantSkillsVerificationIcon';

interface ApplicantSkillsVerificationSourceProps {
    iconSource: string;
    name: string;
    alt: string;
    className: string;
    grey?: boolean;
    black?: boolean;
}

export const ApplicantSkillsVerificationSource: FC<ApplicantSkillsVerificationSourceProps> = ({
    iconSource,
    name,
    alt,
    className,
    grey,
    black,
}) => {
    return (
        <div className={className} data-qa={'verification-method-source'}>
            {!black && !grey && <ApplicantSkillsVerificationIcon alt={alt} iconSource={iconSource} isBig={false} />}
            {(black || grey) && (
                <div
                    className={classnames({
                        'skills-verification-methods-branding-book-icon': true,
                        'skills-verification-methods-rank-icon': true,
                        'skills-verification-methods-branding-book-icon-black': black,
                    })}
                />
            )}
            <span
                className={classnames('resume-key-skills-verification-method-skill', {
                    'resume-key-skills-verification-method-skill-black': black,
                    'resume-key-skills-verification-method-skill-grey': grey,
                })}
            >
                {name}
            </span>
        </div>
    );
};

export default ApplicantSkillsVerificationSource;
