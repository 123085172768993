import React, { ComponentType, ReactNode, RefObject } from 'react';

import { Action, Drop, BottomSheet, NavigationBar, useBreakpoint } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

type Props = {
    activatorRef: RefObject<HTMLElement>;
    visible: boolean;
    children: ReactNode;
    onClose: () => void;
};

const TrlKeys = {
    bottomSheetTitle: 'jobSearchStatus.searchStatusText.v2',
    bottomSheetSubtitle: 'jobSearchStatus.description.v2',
};

const JobSearchStatusContainer: ComponentType<Props> = translation(
    ({ trls, visible, activatorRef, onClose, children }) => {
        const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain);

        const { isMobile } = useBreakpoint();

        const bottomSheetTitle = trls[TrlKeys.bottomSheetTitle].replace('{0}', topLevelDomain as string);

        return isMobile ? (
            <BottomSheet
                visible={visible}
                header={
                    <NavigationBar
                        title={bottomSheetTitle}
                        subtitle={trls[TrlKeys.bottomSheetSubtitle]}
                        right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                        size="large"
                    />
                }
                onClose={onClose}
            >
                {children}
            </BottomSheet>
        ) : (
            <Drop
                placement="bottom-right"
                widthEqualToActivator
                visible={visible}
                onClose={onClose}
                activatorRef={activatorRef}
            >
                {children}
            </Drop>
        );
    }
);

export default JobSearchStatusContainer;
