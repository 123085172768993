/* eslint-disable import/prefer-default-export */
import { useCallback, ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import { Button, ButtonStyle, ButtonSize } from '@hh.ru/magritte-ui';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { cloneResume } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/api/cloneResume';

const TrlKeys = {
    action: 'resumeList.actionline.duplicate',
};

type Props = {
    hash: string;
    icon?: ReactElement;
    size?: ButtonSize;
    style?: ButtonStyle;
    resumeId: number;
    stretched?: boolean;
};

export const DuplicateAction = translation<Props>(
    ({ hash, trls, style = 'accent', size = 'small', resumeId, stretched = false, icon }) => {
        const dispatch = useDispatch();
        const { addNotification } = useNotification();

        const remaining = useSelector((state) => state?.resumeLimits?.remaining || 0);

        const handleClick = useCallback(() => {
            void dispatch(cloneResume({ resume: hash }, addNotification));

            resumeActionLinkButtonClick({ buttonName: 'resume_duplicate', resumeId });
        }, [addNotification, dispatch, hash, resumeId]);

        if (remaining === 0) {
            return null;
        }
        const additionalProps: { icon?: never } | { icon: ReactElement } = { ...(icon && { icon }) };

        return (
            <Button
                size={size}
                style={style}
                mode="tertiary"
                data-qa="resume-dublicate"
                onClick={handleClick}
                stretched={stretched}
                {...additionalProps}
            >
                {trls[TrlKeys.action]}
            </Button>
        );
    }
);
