/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';

import resumeStatsLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_stats_link_button_click';
import { Card, Text, HSpacingContainer, VSpacing, Divider, HSpacing, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import { ResumeStatisticsItem } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeStatistics/ResumeStatisticsItem';

import styles from './styles.less';

const TrlKeys = {
    title: 'resumeList.statistics.text.title',
};

type Props = {
    hash: string;
    resumeId: number;
};

export const ResumeStatistics = translation<Props>(({ trls, hash, resumeId }) => {
    const { isMobile } = useBreakpoint();
    const statistics = useSelector(
        ({ applicantResumesStatistics }) => applicantResumesStatistics?.resumes?.[resumeId]?.statistics
    );

    const handleViewsClick = useCallback(() => {
        resumeStatsLinkButtonClick({ buttonName: 'resume_view_history', resumeId });
    }, [resumeId]);

    const handleInvitationsClick = useCallback(() => {
        resumeStatsLinkButtonClick({ buttonName: 'resume_invitations', resumeId });
    }, [resumeId]);

    if (!statistics) {
        return null;
    }

    return (
        <>
            <HSpacingContainer default={0}>
                <HSpacing default={12} />
                <Text typography="label-2-regular">{trls[TrlKeys.title]}</Text>
            </HSpacingContainer>
            <VSpacing default={12} />
            <Card showBorder stretched={isMobile} borderRadius={isMobile ? 12 : 16}>
                <div className={styles.list}>
                    <ResumeStatisticsItem type="searchShows" {...statistics.searchShows} />
                    <Divider mode="vertical" length={isMobile ? 50 : 22} />
                    <ResumeStatisticsItem
                        type="views"
                        link={`/applicant/resumeview/history?resumeHash=${hash}`}
                        onClick={handleViewsClick}
                        {...statistics.views}
                    />
                    <Divider mode="vertical" length={isMobile ? 50 : 22} />
                    <ResumeStatisticsItem
                        type="invitations"
                        link="/applicant/negotiations?state=INVITATION"
                        onClick={handleInvitationsClick}
                        {...statistics.invitations}
                    />
                </div>
            </Card>
        </>
    );
});
