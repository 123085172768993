/* eslint-disable import/prefer-default-export */
import { useCallback, useRef } from 'react';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import resumeRecommendationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_button_click';
import { Button, TooltipHover } from '@hh.ru/magritte-ui';

import { ApplicantServices } from 'src/components/ApplicantServices/common';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useBotUpdateResumeContext } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/botUpdateResume';

const TrlKeys = {
    error: 'rb.touch.error',
    update: 'resumeList.update',
    renewal: 'myresume.publish.renewal',
    autoRenewal: 'myresume.publish.autoRenewal',
};

type Props = {
    error?: boolean;
    message?: string;
    disabled?: boolean;
    isButton?: boolean;
    doUpdate?: () => void;
    stretched?: boolean;
    isAutoRenewalRecommended?: boolean;
    renewalTime?: {
        nearestIntervalEndTime?: number;
        nearestIntervalStartTime?: number;
    };
};

export const RenewalButton = translation<Props>(
    ({
        trls,
        error = false,
        message = '',
        disabled = false,
        doUpdate,
        isButton = false,
        stretched = false,
        renewalTime = {},
        isAutoRenewalRecommended = false,
    }) => {
        const isZp = useIsZarplataPlatform();
        const { resumeId, recommendationDetails } = useBotUpdateResumeContext();

        const activatorRef = useRef<HTMLButtonElement | null>(null);

        const isRenewalAvailable = useSelector(({ applicantPaymentServices }) =>
            applicantPaymentServices.includes(ApplicantServices.ResumeRenewal)
        );

        const isRenewalEnabled = Boolean(renewalTime?.nearestIntervalStartTime);
        const surveyProfession = recommendationDetails?.surveyProfession || '';
        const shouldAutoRenewal = isAutoRenewalRecommended && isRenewalAvailable;

        let displayMessage = null;
        if (error) {
            displayMessage = trls[TrlKeys.error];
        } else if (disabled || isRenewalEnabled) {
            displayMessage = message;
        }

        const onClick = useCallback(() => {
            if (isZp && shouldAutoRenewal) {
                return;
            }

            if (doUpdate) {
                doUpdate?.();
            }

            resumeActionLinkButtonClick({ buttonName: 'resume_update', resumeId });

            if (isAutoRenewalRecommended) {
                resumeRecommendationButtonClick({
                    type: 'resumeRenewal',
                    resumeId,
                    surveyProfession,
                });
            }
        }, [doUpdate, isAutoRenewalRecommended, isZp, surveyProfession, resumeId, shouldAutoRenewal]);

        const isButtonDisabled =
            (isZp && shouldAutoRenewal) ||
            (disabled && isRenewalEnabled) ||
            (!isRenewalAvailable && isAutoRenewalRecommended);

        return (
            <>
                <Button
                    stretched={stretched}
                    ref={activatorRef}
                    size="small"
                    mode={isButton ? 'primary' : 'tertiary'}
                    style="accent"
                    onClick={onClick}
                    data-qa="resume-update-button_actions"
                    disabled={isButtonDisabled}
                >
                    {shouldAutoRenewal && !isZp ? trls[TrlKeys.autoRenewal] : trls[TrlKeys.update]}
                </Button>
                {displayMessage && (
                    <TooltipHover
                        data-qa="resume-update-button-tooltip"
                        placement="right-bottom"
                        activatorRef={activatorRef}
                    >
                        {displayMessage}
                    </TooltipHover>
                )}
            </>
        );
    }
);
