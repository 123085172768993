/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { push } from 'connected-react-router';

import { Text, HSpacingContainer } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';

import translation from 'src/components/translation';
import { StatisticsCount } from 'src/models/applicantResumesStatistics';

import styles from './styles.less';

const TrlKeys = {
    emptyNum: 'resumeList.statistics.emptyNum',
    'searchShows.one': 'resumeList.statistics.searchShows.one',
    'searchShows.some': 'resumeList.statistics.searchShows.some',
    'searchShows.many': 'resumeList.statistics.searchShows.many',
    'views.one': 'resumeList.statistics.views.one',
    'views.some': 'resumeList.statistics.views.some',
    'views.many': 'resumeList.statistics.views.many',
    'invitations.one': 'resumeList.statistics.invitations.one',
    'invitations.some': 'resumeList.statistics.invitations.some',
    'invitations.many': 'resumeList.statistics.invitations.many',
    week: 'resumeList.statistics.week',
    comparison: 'resumeList.statistics.comparison',
    comparisonPositive: 'resumeList.statistics.comparison.positive',
    comparisonNegative: 'resumeList.statistics.comparison.negative',
    empty: 'resumeList.statistics.empty',
    comparisonEqual: 'resumeList.statistics.comparisonEqual',
};

const dataQA = {
    views: 'count-new-views',
    invitations: 'new-invitations',
    searchShows: 'search-shows',
};

type Props = {
    type: 'searchShows' | 'views' | 'invitations';
    link?: string;
    onClick?: () => void;
} & Partial<StatisticsCount>;

export const ResumeStatisticsItem = translation<Props>(({ type, link, count = 0, countNew = 0, trls, onClick }) => {
    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
        if (link) {
            dispatch(push(link));
            if (onClick) {
                onClick();
            }
        }
    }, [dispatch, link, onClick]);

    if (count === undefined) {
        return null;
    }

    return (
        <div
            className={classnames(styles.item, {
                [styles.itemActive]: link,
            })}
            data-qa={dataQA[type]}
            onClick={handleClick}
        >
            <HSpacingContainer default={4}>
                <Text typography="subtitle-1-semibold">{count}</Text>
                {!!countNew && (
                    <Text style="positive" typography="subtitle-1-semibold">
                        + {countNew}
                    </Text>
                )}
            </HSpacingContainer>
            <Text typography="label-3-regular" style="secondary">
                <Conversion
                    value={countNew || count}
                    hasValue={false}
                    one={trls[TrlKeys[`${type}.one`]]}
                    some={trls[TrlKeys[`${type}.some`]]}
                    many={trls[TrlKeys[`${type}.many`]]}
                    zero={trls[TrlKeys[`${type}.many`]]}
                />
            </Text>
        </div>
    );
});
