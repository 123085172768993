import classNames from 'classnames';

import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './finishModal.less';

interface TestRankProps {
    rank?: number | null;
    background?: boolean;
    success?: boolean;
    name?: string;
    icon?: boolean;
    black?: boolean;
}

const TrlKeys = {
    3: 'tests.card.level.advanced',
    2: 'tests.card.level.middle',
    1: 'tests.card.level.beginner',
};

const TestRank: TranslatedComponent<TestRankProps> = ({
    trls,
    rank,
    background = false,
    success = false,
    name = '',
    icon,
    black,
}) => {
    if (!rank) {
        return <></>;
    }

    return (
        <>
            <div
                className={classNames(styles.rank, {
                    [styles.background]: background,
                    [styles.success]: success,
                    [styles.black]: black,
                })}
                data-qa={'verification-method-level'}
            >
                {icon && (
                    <div
                        className={classNames(
                            'skills-verification-methods-rank-icon-margin',
                            'skills-verification-methods-rank-icon',
                            'skills-verification-methods-rank',
                            {
                                [`skills-verification-methods-rank-${rank}-icon`]: true,
                                [`skills-verification-methods-rank-${rank}-success-icon`]: success,
                                [`skills-verification-methods-rank-${rank}-black-icon`]: black,
                            }
                        )}
                    />
                )}
                {success ? (
                    <Text typography="label-3-regular" style="positive">
                        {name || trls[TrlKeys[rank as keyof typeof TrlKeys]]}
                    </Text>
                ) : (
                    <Text typography="label-3-regular" style="secondary">
                        {name || trls[TrlKeys[rank as keyof typeof TrlKeys]]}
                    </Text>
                )}
                {icon && <VSpacing default={2} />}
            </div>
        </>
    );
};

export default translation(TestRank);
