/* eslint-disable import/prefer-default-export */
import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { formatToReactComponent } from 'bloko/common/trl';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useResumeAudit } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/hooks/useResumeAudit';
import { ApplicantService } from 'src/utils/constants/applicantServices';

const TrlKeys = {
    buttonTextShort: 'resumes.recommendations.resume_audit.pay_button_recommendation.short',
};

export const RecommendationButton = translation<{
    resumeHash: string;
}>(({ resumeHash, trls }) => {
    const audit = useResumeAudit(resumeHash);
    const userType = useSelector((state) => state.userType);
    const isHeadHunter = useIsHeadHunterPlatform();

    if (!audit || !isHeadHunter || audit.order) {
        return null;
    }

    return (
        <Button
            Element={Link}
            size="small"
            mode="secondary"
            style="accent"
            target="_blank"
            to={`/applicant-services/payment/${ApplicantService.ResumeAudit}?resumeHash=${resumeHash}`}
            onClick={() => {
                Analytics.sendEvent(userType, 'applicant_myresume_resume_audit', 'click_resume_audit');
            }}
        >
            {formatToReactComponent(trls[TrlKeys.buttonTextShort], {
                '{0}': <FormatMoney currency={audit.price?.currency}>{audit?.price?.amount || 0}</FormatMoney>,
            })}
        </Button>
    );
});
