/* eslint-disable import/prefer-default-export */
import { useSelector } from 'src/hooks/useSelector';
import { ResumeAuditOrder, ResumeAuditPrice, ResumeAuditStatus } from 'src/utils/constants/applicantServices';

const MONTH_IN_MS = 1000 * 60 * 60 * 24 * 30;

export const useResumeAudit = (resumeHash: string): { order?: ResumeAuditOrder; price: ResumeAuditPrice } | null => {
    const audit = useSelector((state) => state.resumeAuditRecommendation);
    const order = audit?.orders.find(
        (order) => order.resume?.hash === resumeHash && order.status !== ResumeAuditStatus.Archived
    );
    const hasApplicableResumes =
        audit?.applicableResumes.includes(resumeHash) &&
        !audit?.orders.find(
            (order) => order.resume?.hash === resumeHash && order.activationTime > Date.now() - MONTH_IN_MS
        );

    const showBanner = hasApplicableResumes || !!order;

    if (!audit || !showBanner) {
        return null;
    }

    return { order, price: audit.price };
};
