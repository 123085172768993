import { useEffect, useRef, useCallback, useContext, FC, PropsWithChildren } from 'react';

import Analytics from '@hh.ru/analytics-js';
import resumeRecommendationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_button_click';
import resumeRecommendationElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_element_shown';
import Button, { ButtonKind, ButtonScale, ButtonAppearance } from 'bloko/blocks/button';

import BotUpdateResumeContext from 'src/pages/ApplicantResumes/components/BotUpdateResumeContext';

const UpdateByBotButton: FC<PropsWithChildren> = ({ children }) => {
    const ref = useRef<HTMLButtonElement>(null);

    const { resumeId, recommendationDetails, chatBotHref } = useContext(BotUpdateResumeContext);

    useEffect(() => {
        if (ref.current) {
            resumeRecommendationElementShown(ref.current, {
                type: 'updateResumeByBot',
                resumeId,
                surveyProfession: recommendationDetails?.surveyProfession || '',
            });
        }
    }, [resumeId, recommendationDetails]);

    const handleClick = useCallback(() => {
        Analytics.sendEvent('applicant', 'resume_renew_bot', 'click');
        resumeRecommendationButtonClick({
            type: 'updateResumeByBot',
            resumeId,
            surveyProfession: recommendationDetails?.surveyProfession || '',
        });
    }, [resumeId, recommendationDetails]);

    return (
        <Button
            innerRef={ref}
            kind={ButtonKind.Primary}
            scale={ButtonScale.Small}
            stretched
            Element="a"
            href={chatBotHref}
            appearance={ButtonAppearance.Outlined}
            onClick={handleClick}
            data-qa="resume-recommendations__button_update-by-bot"
        >
            {children}
        </Button>
    );
};

export default UpdateByBotButton;
