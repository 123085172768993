import { NotificationKind } from 'bloko/blocks/notificationManager/notificationManager';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    resumeError: 'resume.error',
    saveSuccess: 'applicant.universityMapping.saveSuccess',
    conversionSuccess: 'resumeList.resumesConvertedNotification.success',
    maxResumes: 'supernova.navi.createResume.maxResumes',
    conversionError: 'resumeList.resumesConvertedNotification.error',
};

const ResumeSaveError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.resumeError]}</>;
export const resumeSaveError = {
    Element: translation(ResumeSaveError),
    kind: NotificationKind.Error,
};

const ResumeSaveSuccess: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.saveSuccess]}</>;
export const resumeSaveSuccess = {
    Element: translation(ResumeSaveSuccess),
    kind: NotificationKind.Ok,
};
