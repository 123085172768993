/* eslint-disable import/prefer-default-export */
import { FC, useRef, useState } from 'react';

import { ActionList, Action } from '@hh.ru/magritte-ui';
import {
    EllipsisHorizontalOutlinedSize16,
    CopyOutlinedSize24,
    EyeOutlinedSize24,
    PenOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';

import { DuplicateAction } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/DuplicateAction';
import { EditAction } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/EditAction';
import { VisibilityAction } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/VisibilityAction';

import styles from './styles.less';

type Props = {
    hash: string;
    showEdit: boolean;
    editUrl: string;
    resumeId: number;
    showDuplicate: boolean;
    showVisibility: boolean;
};

export const DesktopActionsMenu: FC<Props> = ({ showVisibility, showDuplicate, showEdit, hash, resumeId, editUrl }) => {
    const activatorRef = useRef(null);
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Action
                icon={EllipsisHorizontalOutlinedSize16}
                ref={activatorRef}
                mode="primary"
                style="accent"
                onClick={() => setVisible(!visible)}
            />
            <ActionList
                visible={visible}
                onClose={() => setVisible(false)}
                dropProps={{
                    placement: 'bottom-right',
                    activatorRef,
                }}
            >
                <ul role="menu" aria-orientation="vertical" className={styles.menu}>
                    {showVisibility && (
                        <li role="menuitem" className={styles.item}>
                            <VisibilityAction
                                stretched
                                size="small"
                                icon={<EyeOutlinedSize24 />}
                                hash={hash}
                                style="neutral"
                                resumeId={resumeId}
                            />
                        </li>
                    )}
                    {showDuplicate && (
                        <li role="menuitem" className={styles.item}>
                            <DuplicateAction
                                stretched
                                size="small"
                                hash={hash}
                                icon={<CopyOutlinedSize24 />}
                                style="neutral"
                                resumeId={resumeId}
                            />
                        </li>
                    )}
                    {showEdit && (
                        <li role="menuitem" className={styles.item}>
                            <EditAction
                                stretched
                                size="small"
                                icon={<PenOutlinedSize24 />}
                                hash={hash}
                                style="neutral"
                                href={editUrl}
                                resumeId={resumeId}
                            />
                        </li>
                    )}
                </ul>
            </ActionList>
        </>
    );
};
