import { useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import formatDate from 'date-fns/format';
import PropTypes from 'prop-types';

import resumeRecommendationElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_element_shown';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { resumeRecommendationType } from 'src/utils/constants/resumeRecommendations';
import { resumeStatusType } from 'src/utils/constants/resumeStatuses';
import { humanDatesRulesType } from 'src/utils/humanDatesRules';

import BotUpdateResumeContext from 'src/pages/ApplicantResumes/components/BotUpdateResumeContext';
import ResumeAudit from 'src/pages/ApplicantResumes/components/ResumeAudit';
import ResumeRecommendationText from 'src/pages/ApplicantResumes/components/ResumeRecommendationText';
import { toUpdateType } from 'src/pages/ApplicantResumes/components/resumeToUpdate';

const ResumeRecommendations = ({ resume, recommendation, recommendationDetails, trls }) => {
    const resumeHash = resume?._attributes?.hash;
    const resumeId = resume?._attributes?.id;
    const recommendationRef = useRef();
    const isHeadHunter = useIsHeadHunterPlatform();

    const { successUpdate, canUpdateResumeByBot } = useContext(BotUpdateResumeContext);

    useEffect(() => {
        if (recommendationRef.current) {
            resumeRecommendationElementShown(recommendationRef.current, {
                type: recommendation,
                resumeId,
                surveyProfession: recommendationDetails?.surveyProfession,
            });
        }
    }, [recommendation, recommendationDetails, resumeId]);

    if (!recommendation || !isHeadHunter) {
        return (
            <div className="applicant-resumes-recommendations_with-background">
                <ResumeAudit resumeHash={resumeHash} resumeId={resumeId} withoutTopSpacing={true} />
            </div>
        );
    }

    const renderRecommendationsText = () => {
        if (canUpdateResumeByBot && successUpdate) {
            return (
                <p className="applicant-resumes-recommendations-text-body" suppressHydrationWarning>
                    {format(trls[ResumeRecommendations.trls.successUpdateMessage], {
                        '{0}': formatDate(new Date(), 'HH:mm'),
                    })}
                </p>
            );
        }

        return (
            <p className="applicant-resumes-recommendations-text-body">
                <ResumeRecommendationText
                    recommendation={recommendation}
                    recommendationDetails={recommendationDetails}
                />
            </p>
        );
    };

    return (
        <div className="applicant-resumes-recommendations applicant-resumes-recommendations_with-background">
            <div className="applicant-resumes-recommendations-text">{renderRecommendationsText()}</div>
            <ResumeAudit resumeHash={resumeHash} resumeId={resumeId} />
        </div>
    );
};

ResumeRecommendations.propTypes = {
    trls: PropTypes.object.isRequired,
    skillsSurveyTrls: PropTypes.shape({
        resumeStartSurveyProfession: PropTypes.string,
    }),
    recommendation: resumeRecommendationType,
    recommendationDetails: PropTypes.shape({
        surveyProfession: PropTypes.string,
        translations: PropTypes.shape({
            resumeStartSurveyProfession: PropTypes.string,
            resumeRecommendationTextProfession: PropTypes.string,
        }),
    }),
    resume: PropTypes.shape({
        _attributes: PropTypes.shape({
            hash: PropTypes.string.isRequired,
            id: PropTypes.string,
            status: resumeStatusType.isRequired,
            update_timeout: PropTypes.number.isRequired, // eslint-disable-line camelcase
            updated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            isSearchable: PropTypes.bool.isRequired,
        }).isRequired,
        humanDatesRules: humanDatesRulesType,
        toUpdate: toUpdateType.isRequired,
        renewalTime: PropTypes.object,
    }).isRequired,
};

ResumeRecommendations.trls = {
    actionEditResume: 'resumeList.recommendations.action.edit',
    actionChangeVisibility: 'resumeList.recommendations.action.visibility',
    actionCorrectResume: 'resumeList.recommendations.action.correct',
    verifyPhoneSubmit: 'resumes.phoneVerification.resumeToAuth.submitPhone',
    undergoSkillsSurveyBase: 'resumeList.recommendations.text.startSurvey.base',
    suitableVacancies: 'resumeList.actionline.suitableVacancies',
    successUpdateMessage: 'botUpdateResume.successUpdateMessage',
};

export const ResumeRecommendationsRaw = translation(ResumeRecommendations);
export const mapStateToProps = (state) => ({
    url: state.request.url,
    suitableVacancyByResume: state.applicantSuitableVacancyByResume,
});
export const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...dispatchProps,
        ...stateProps,
    };
};

export default connect(mapStateToProps, null, mergeProps)(ResumeRecommendationsRaw);
