import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    maxResimesTitle: 'resumeForm.maxResumes.notification.1',
    maxResumesText: 'resumeForm.maxResumes.notification.2',
    visibilityErrorTitle: 'resumeForm.error.visibility.title',
    visibilityErrorText: 'resumeForm.error.visibility.content',
};

const MaxResumeLimit: TranslatedComponent = ({ trls }) => {
    const max = useSelector((state) => state.resumeLimits?.max) || 0;
    return (
        <>
            <NotificationHeading>{trls[TrlKeys.maxResimesTitle]}</NotificationHeading>
            {format(trls[TrlKeys.maxResumesText], { '{0}': max })}
        </>
    );
};

export const maxResumeLimitNotification = {
    Element: translation(MaxResumeLimit),
    kind: 'error',
};

const ResumeFormVisibilityError: TranslatedComponent = ({ trls }) => (
    <>
        <NotificationHeading>{trls[TrlKeys.visibilityErrorTitle]}</NotificationHeading>
        {trls[TrlKeys.visibilityErrorText]}
    </>
);

export const resumeFormVisibilityErrorNotification = {
    Element: translation(ResumeFormVisibilityError),
    kind: 'error',
};
