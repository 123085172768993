import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import verificationKeySkillLearnButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_learn_button_click';
import verificationKeySkillResultNextButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_result_next_button_click';
import verificationKeySkillResultScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_result_screen_shown';
import verificationKeySkillVacancyButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_vacancy_button_click';
import {
    Modal,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Text,
    Action,
    Link,
    Avatar,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import {
    CrossOutlinedSize24,
    CheckOutlinedSize16,
    CrossCircleFilledSize24,
    VerifiedBadgeOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { Link as LinkRedux } from '@hh.ru/redux-spa-middleware';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import ApplicantSkillsVerificationIcon from 'src/components/ApplicantSkillsVerificationIcon';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    ApplicantSkillsVerificationResult,
    ApplicantSkillsVerificationResultKeys,
    refreshApplicantSkillsVerificationResults,
} from 'src/models/applicantSkillsVerificationResult';
import { CareerLinksKeys, CareerPlatformState } from 'src/models/careerPlatformState';

import TestRank from './TestRank';

import styles from './finishModal.less';

interface TestFinishModalProps {
    rank?: number | null;
    success?: boolean;
    name?: string;
    education?: boolean;
    correctAnswers: number;
    questionsAmount: number;
    educationUrl: string;
    results: ApplicantSkillsVerificationResult;
    isSkill: boolean;
    sourceName?: string;
}

const TrlKeys = {
    0: '',
    3: 'test.level.advanced',
    2: 'test.level.middle',
    1: 'test.level.beginner',
    correctAnswers: 'test.answers',
    buttonClose: 'test.button.close',
    buttonEducation: 'test.button.education',
    many: 'test.answer.many',
    skillLevelSuccess: 'test.skill.success',
    skillLevelFailure: 'test.skill.failure',
    langLevelSuccess: 'test.language.success',
    langLevelFailure: 'test.language.failure',
    careerSuccess: 'test.success.career',
    careerFailure: 'test.failure.career',
    failureText: 'test.failure.text',
    successText: 'test.success.text',
};

const renderCard = (additionalText: string, defaultText: string) => (
    <div className={styles.modal}>
        <Card style="warning" padding={12} borderRadius={16} stretched>
            <Text style="warning">{defaultText}</Text>
        </Card>
        {additionalText && (
            <Text style="secondary" typography="label-2-regular">
                {additionalText}
            </Text>
        )}
    </div>
);

const getCareerLink = (
    trls: LangTrls,
    careerPlatformLinks: CareerPlatformState | null,
    onClick: () => void,
    mobile: boolean
) => {
    if (!careerPlatformLinks?.links) {
        return null;
    }

    return (
        <Button
            Element={LinkRedux}
            to={careerPlatformLinks.links[CareerLinksKeys.Courses]}
            data-qa="go-to-career"
            target={'_blank'}
            mode="primary"
            style="accent"
            onClick={onClick}
            stretched={mobile}
        >
            {trls[TrlKeys.buttonEducation]}
        </Button>
    );
};

const TestFinishModal: TranslatedComponent<TestFinishModalProps> = ({
    trls,
    rank = 2,
    success = false,
    name = '',
    education,
    correctAnswers,
    questionsAmount,
    educationUrl,
    results,
    isSkill,
}) => {
    const careerLinks = useSelector((state) => {
        if (results.items?.[0]?.category === 'LANG') {
            return state.careerPlatformLinks;
        }
        return success ? state.careerPlatformLinks : state.careerPlatformLinksForSkill;
    });
    const careerEducation = Boolean(!education && careerLinks && careerLinks.links);
    const [isVisible, setVisible] = useState(true);

    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();
    const testResultAnalyticsObj = {
        verificationTestName: name,
        resultType: success ? 1 : 0,
        isBrand: results.method[ApplicantSkillsVerificationResultKeys.BrandingSettings].enabled,
        isPaidClick:
            results.method[ApplicantSkillsVerificationResultKeys.BrandingSettings][
                ApplicantSkillsVerificationResultKeys.CtaButtonEnabled
            ],
        nameBrand: results.method.source.name,
        verificationMethodId: results.method.id,
        verificationSourceId: results.method.source.id,
        levelId: rank || 2,
    };

    useEffect(() => {
        if (rank) {
            verificationKeySkillResultScreenShown(testResultAnalyticsObj);
        }
    }, [rank, testResultAnalyticsObj]);
    if (!rank) {
        return <></>;
    }

    let headerText;
    const skillLevelKey = rank <= 3 ? trls[TrlKeys[rank as keyof typeof TrlKeys]] : '';

    if (success) {
        headerText = format(trls[TrlKeys.skillLevelSuccess], {
            '{0}': isSkill ? skillLevelKey.toLowerCase() : '',
        });
    } else {
        headerText = format(trls[TrlKeys.skillLevelFailure], {
            '{0}': isSkill ? skillLevelKey : '',
        });
    }
    if (!isSkill) {
        headerText = success ? trls[TrlKeys.langLevelSuccess] : trls[TrlKeys.langLevelFailure];
    }

    const correctAnswersText = format(trls[TrlKeys.correctAnswers], {
        '{0}': correctAnswers,
        '{1}': trls[TrlKeys.many],
        '{2}': questionsAmount,
    });

    const handleClose = () => {
        setVisible(false);
        dispatch(refreshApplicantSkillsVerificationResults(null));
    };

    const handleClickGoLearn = () => {
        verificationKeySkillLearnButtonClick(testResultAnalyticsObj);
        verificationKeySkillResultNextButtonClick({ type: 'learn', ...testResultAnalyticsObj });
        handleClose();
    };

    const handleCloseModal = () => {
        handleClose();
        verificationKeySkillVacancyButtonClick(testResultAnalyticsObj);
        verificationKeySkillResultNextButtonClick({ type: 'close', ...testResultAnalyticsObj });
        dispatch(push('applicant/resumes'));
    };

    const handleClickCareerLink = () => {
        verificationKeySkillResultNextButtonClick({
            type: success ? 'learn_new' : 'look_at_courses',
            ...testResultAnalyticsObj,
        });
        handleClose();
    };

    const getText = () => {
        if (success) {
            if (education) {
                return isMobile ? (
                    <div>
                        <Text style="secondary">{trls[TrlKeys.successText]}.</Text>
                        <Text style="secondary">{results.items?.[0]?.content}</Text>
                    </div>
                ) : (
                    <Text style="secondary">{`${trls[TrlKeys.successText]}. ${results.items?.[0]?.content}`}</Text>
                );
            }
            if (careerEducation) {
                return isMobile ? (
                    <div>
                        <Text style="secondary">{trls[TrlKeys.successText]}.</Text>
                        <Text style="secondary">{trls[TrlKeys.careerSuccess]}</Text>
                    </div>
                ) : (
                    <Text style="secondary">{`${trls[TrlKeys.successText]}. ${trls[TrlKeys.careerSuccess]}`}</Text>
                );
            }
            return <Text style="secondary">{trls[TrlKeys.successText]}</Text>;
        }

        if (education) {
            return renderCard(results.items?.[0]?.content, trls[TrlKeys.failureText]);
        }

        if (careerEducation) {
            return renderCard(trls[TrlKeys.careerFailure], trls[TrlKeys.failureText]);
        }

        return renderCard('', trls[TrlKeys.failureText]);
    };

    const getContent = () => {
        return (
            !success && (
                <div className={styles.result}>
                    <CheckOutlinedSize16 />
                    <Text style="primary" typography="label-3-regular" data-qa={correctAnswers.toString()}>
                        {correctAnswersText}
                    </Text>
                </div>
            )
        );
    };

    const getAvatar = () => {
        let content;

        if (education) {
            content = (
                <ApplicantSkillsVerificationIcon
                    iconSource={results.method.icon.defaults.original}
                    alt="brand"
                    isBig={true}
                />
            );
        } else if (success) {
            content = (
                <Avatar
                    shapeCircle={true}
                    icon={<VerifiedBadgeOutlinedSize24 initial={'positive'} />}
                    mode="icon"
                    size={48}
                    style="positive"
                    aria-label="success"
                />
            );
        } else {
            content = (
                <Avatar
                    shapeCircle={true}
                    icon={<CrossCircleFilledSize24 initial={'negative'} />}
                    mode="icon"
                    size={48}
                    style="negative"
                    aria-label="fail"
                />
            );
        }

        return content;
    };

    return isMobile ? (
        <BottomSheet
            visible={isMobile}
            onClose={handleCloseModal}
            footer={
                education ? (
                    <BottomSheetFooter>
                        <Button
                            Element={LinkRedux}
                            to={educationUrl}
                            stretched={true}
                            data-qa="go-to-education"
                            mode="primary"
                            style="accent"
                            onClick={handleClickGoLearn}
                        >
                            {trls[TrlKeys.buttonEducation]}
                        </Button>
                    </BottomSheetFooter>
                ) : (
                    <BottomSheetFooter>
                        {careerEducation && getCareerLink(trls, careerLinks, handleClickCareerLink, isMobile)}
                    </BottomSheetFooter>
                )
            }
        >
            <div className={styles.mobile}>
                {getAvatar()}
                <Text typography="title-4-semibold">{headerText}</Text>
                <TestRank
                    rank={rank}
                    background
                    success={success}
                    name={results.items?.[0]?.category === 'LANG' ? results.method.name : name}
                />
                <div className={styles.container}>{getContent()}</div>
                {getText()}
            </div>
        </BottomSheet>
    ) : (
        <Modal
            visible={isVisible}
            onClose={handleCloseModal}
            title={headerText}
            actions={
                !education && (
                    <Action mode="secondary" style="neutral" icon={CrossOutlinedSize24} onClick={handleCloseModal} />
                )
            }
            options={
                <div className={styles.modal}>
                    <div className={styles.container}>
                        <TestRank
                            rank={rank}
                            background
                            success={success}
                            name={results.items?.[0]?.category === 'LANG' ? results.method.name : name}
                        />
                        {getContent()}
                    </div>
                    {getText()}
                </div>
            }
            leftButtons={
                (education || careerEducation) && (
                    <Button data-qa="close" mode="tertiary" onClick={handleCloseModal} style="accent">
                        {trls[TrlKeys.buttonClose]}
                    </Button>
                )
            }
            rightButtons={
                education ? (
                    <Link Element={LinkRedux} to={educationUrl}>
                        <Button data-qa="go-to-education" mode="primary" onClick={handleClickGoLearn} style="accent">
                            {trls[TrlKeys.buttonEducation]}
                        </Button>
                    </Link>
                ) : (
                    careerEducation && getCareerLink(trls, careerLinks, handleClickCareerLink, isMobile)
                )
            }
        ></Modal>
    );
};

export default translation(TestFinishModal);
