/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import addDays from 'date-fns/addDays';

import Analytics from '@hh.ru/analytics-js';
import { Card, Text, Button } from '@hh.ru/magritte-ui';
import { formatToReactComponent } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useResumeAudit } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/hooks/useResumeAudit';
import {
    ApplicantService,
    APPLICANT_SERVICE_ORDER_URL,
    ResumeAuditStatus,
} from 'src/utils/constants/applicantServices';

const TrlKeys = {
    title: 'resumes.recommendations.resume_audit.title',
    button: 'resumes.recommendations.resume_audit.pay_button',
    buttonText: 'resumes.recommendations.resume_audit.pay_button_recommendation',
    buttonReady: 'resumes.recommendations.resume_audit.ready_button',
    buttonTextShort: 'resumes.recommendations.resume_audit.pay_button_recommendation.short',
    titleStatusFinished: 'resumes.recommendations.resume_audit.title.finished',
    descriptionStatusDirty: 'resumes.recommendations.resume_audit.description.1',
    descriptionStatusWaiting: 'resumes.recommendations.resume_audit.description.2',
};

type Props = {
    resumeHash: string;
};

export const ResumeAudit = translation<Props>(({ resumeHash, trls }) => {
    const audit = useResumeAudit(resumeHash);
    const userType = useSelector((state) => state.userType);

    const sendButtonAnalytics = useCallback(() => {
        Analytics.sendEvent(userType, 'applicant_myresume_resume_audit', 'click_resume_audit');
    }, [userType]);

    if (!audit) {
        return null;
    }

    const order = audit.order;

    if (!order) {
        return null;
    }

    const renderDescription = () => {
        if (!order) {
            return (
                <Text style="secondary" typography="custom-3-medium">
                    {trls[TrlKeys.descriptionStatusDirty]}
                </Text>
            );
        }

        if (order.status === ResumeAuditStatus.Waiting) {
            return (
                <Text style="secondary" typography="custom-3-medium">
                    {formatToReactComponent(trls[TrlKeys.descriptionStatusWaiting], {
                        '{0}': (
                            <span suppressHydrationWarning>
                                {formatDate(order.dueDate || addDays(new Date(), 2), 'd MMMM')}
                            </span>
                        ),
                    })}
                </Text>
            );
        }

        return null;
    };

    const renderButton = () => {
        const hasButton = !order?.status || order?.status !== ResumeAuditStatus.Waiting;
        if (!hasButton) {
            return null;
        }

        if (order?.status === ResumeAuditStatus.Finished) {
            return (
                <Button
                    Element="a"
                    href={`/applicant-services/${APPLICANT_SERVICE_ORDER_URL[ApplicantService.ResumeAudit]}/order/${
                        order.orderCode
                    }`}
                    size="small"
                    mode="tertiary"
                    style="accent"
                >
                    {trls[TrlKeys.buttonReady]}
                </Button>
            );
        }

        const buttonText = formatToReactComponent(trls[TrlKeys.button], {
            '{0}': <FormatMoney currency={audit.price.currency}>{audit.price.amount}</FormatMoney>,
        });

        return (
            <Button
                Element="a"
                size="small"
                mode="primary"
                style="accent"
                href={`/applicant-services/payment/${ApplicantService.ResumeAudit}?resumeHash=${resumeHash}`}
                target="_blank"
                onClick={() => {
                    if (!order) {
                        sendButtonAnalytics();
                    }
                }}
            >
                {buttonText}
            </Button>
        );
    };

    return (
        <Card stretched style="neutral" padding={16} borderRadius={16}>
            <Text>
                {order?.status === ResumeAuditStatus.Finished ? trls[TrlKeys.titleStatusFinished] : trls[TrlKeys.title]}
            </Text>
            {renderDescription()}
            {renderButton()}
        </Card>
    );
});
