import { ComponentType, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import bannerShown from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_element_shown';
import bannerPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_primary_button_click';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { Banner } from 'src/pages/ApplicantResumes/components/redesign/Banner';

const TrlKeys = {
    title: 'resume_merge.activator.title',
    hint: 'resume_merge.activator.hint',
    button: 'resume_merge.activator.button.v2',
};

const ResumeMergeActivator: ComponentType = translation(({ trls }) => {
    const dispatch = useDispatch();

    const analyticsParams = useMemo(
        () =>
            ({
                bannerName: 'merge_resume_banner',
                hhtmSource: 'resume_list',
            } as const),
        []
    );

    const mergeActivatorButtonHandler = () => {
        dispatch(push('/profile/merge'));
        bannerPrimaryButtonClick({ ...analyticsParams, buttonText: trls[TrlKeys.button] });
    };

    return (
        <ElementShownAnchor fn={(element) => bannerShown(element, analyticsParams)}>
            <Banner
                data-qa="resumes-merge-banner"
                style="extra-1"
                title={trls[TrlKeys.title]}
                button={trls[TrlKeys.button]}
                onClick={mergeActivatorButtonHandler}
                description={trls[TrlKeys.hint]}
            />
        </ElementShownAnchor>
    );
});

export default ResumeMergeActivator;
