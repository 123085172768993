import { FC } from 'react';

import { Text, Title } from '@hh.ru/magritte-ui';

import styles from './styles.less';

type Props = {
    title: string;
    size?: 'medium' | 'extra-small';
    isAdv?: boolean;
    style?: 'secondary' | 'primary';
    description: JSX.Element | null;
};

const TextTypographyOptions = {
    medium: 'paragraph-2-regular',
    'extra-small': 'paragraph-2-regular',
} as const;

const ResumeTitle: FC<Props> = ({ isAdv, title, size = 'medium', description, style = 'primary' }) => {
    return (
        <div>
            <div className={styles.title}>
                {isAdv && <div className={styles.advMark}></div>}
                <Title data-qa="resume-title" Element="h3" size={size} style={style}>
                    {title}
                </Title>
            </div>

            {!!description && (
                <div data-qa="title-description" className={styles.description}>
                    <Text typography={TextTypographyOptions[size]} style="secondary">
                        {description}
                    </Text>
                </div>
            )}
        </div>
    );
};

export default ResumeTitle;
