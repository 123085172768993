import useExperiment from 'src/hooks/useExperiment';

import ApplicantResumesPageOld from 'src/pages/ApplicantResumes/components/old/ApplicantResumesPage';
import ApplicantResumesPageNew from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumesPage';

const ApplicantResumesPage = () => {
    const isMagritteExp = useExperiment('magritte_resume_list');
    if (isMagritteExp) {
        return <ApplicantResumesPageNew />;
    }
    return <ApplicantResumesPageOld />;
};

export default ApplicantResumesPage;
